import "./App.css";
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadAwardTime } from "./store/home-vesion";
import "./Homepage.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

// Lazy load the Header component
const Header = lazy(() => import('./components/header.js'));
const MultiLevelMenuComponent = lazy(() => import('./components/menuMultiLevel.js'));
const Home = lazy(() => import('./views/Home'));
const Footer = lazy(() => import('./components/footer'));
const AboutPrise = lazy(() => import('./views/AboutPrise'));
const MobileMenu = lazy(() => import('./components/mobileMenu'));
const Contactus = lazy(() => import('./views/ContactUs'));
const Terms = lazy(() => import('./views/Terms'));
const StaticPage = lazy(() => import('./views/staticPages'));
const SiteMap = lazy(() => import('./views/sitemap'));
const Registration = lazy(() => import('./views/registration'));
const FAQs = lazy(() => import('./views/FAQ'));
const Questionnaire = lazy(() => import('./views/Questionnaire'));
const Photos = lazy(() => import('./views/Photos'));
const Videos = lazy(() => import('./views/Videos'));
const NewsListing = lazy(() => import('./views/NewsListing'));
const NewsDetailsPage = lazy(() => import('./views/NewsDetailsPage'));
const AwardDetailsPage = lazy(() => import('./views/AwardDetailsPage'));
const RegistrationInstitutionsPage = lazy(() => import('./views/RegistrationInstitutions'));
const RegistrationEntityPage = lazy(() => import('./views/RegistrationEntity'));
const LoginFormPage = lazy(() => import('./views/LoginForm'));


function App() {
  const dispatch = useDispatch();
  const awardTime = useSelector((state) => state.vesion.awardTime);
  const showMenu = useSelector((state) => state.menu.show);
  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 850);
  const { i18n } = useTranslation();
  let { uniquename } = useParams();

  useEffect(() => {
    dispatch(loadAwardTime());
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= 850);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [dispatch]);

  function awardData(date) {
    if (date.length) {
      return date[0].eventStartDate;
    }
  }

  return (
    <div className="tahbeerhomepage" dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      <HelmetProvider>
        <Header />
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            {isResponsive ? <MobileMenu /> : <MultiLevelMenuComponent />}
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/:lang" element={<Home />} />
              <Route exact path="/:lang/about.us" element={<AboutPrise />} />
              <Route exact path="/:lang/contact.us" element={<Contactus />} />
              <Route exact path="/:lang/registration.form" element={<Registration />} />
              <Route exact path="/:lang/registration.userentity" element={<RegistrationInstitutionsPage />} />
              <Route exact path="/:lang/terms" element={<Terms />} />
              <Route exact path="/:lang/terms.and.conditions" element={<Terms />} />
              <Route exact path="/:lang/site.map" element={<SiteMap />} />
              <Route exact path="/:lang/photos" element={<Photos />} />
              <Route exact path="/:lang/videos" element={<Videos />} />
              <Route exact path="/:lang/faq" element={<FAQs />} />
              <Route exact path="/:lang/questionnaire" element={<Questionnaire />} />
              <Route exact path="/:lang/:uniquename" element={<StaticPage />} />
              <Route path="*" key={uniquename} element={<NoMatch />} />
              <Route exact path="/:lang/newslisting" element={<NewsListing />} />
              <Route path="/:lang/newsdetails/:newsId" element={<NewsDetailsPage />} />
              <Route path="/:lang/awarddetails/:awardId" element={<AwardDetailsPage />} />
              <Route exact path="/:lang/registration.entity" element={<RegistrationEntityPage />} />
              <Route exact path="/:lang/login.entity" element={<LoginFormPage />} />
            </Routes>
            <Footer />
          </Suspense>
        </Router>
      </HelmetProvider>
    </div>
  );

  function NoMatch() {
    let location = window.location;

    return (
      <div>
        <h3>
          No match for <code>{location.pathname}</code>
        </h3>
      </div>
    );
  }
}

export default App;
